.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.filter-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}
.filter-row div{
  display: flex;
  flex-direction: row;
  padding: 5px;

}
.green{
  width: 30px;
  height: 15px;

  border: none;
  background-color: green;
  border-radius: 5px;
}
.counter1{
  background-color: #c5c5c580;
border: none;
padding: 2px;
width: 40px;
text-align: center;
}
.red{
  width: 30px;
  height: 15px;
  border: none;
 
  background-color: red;
  border-radius: 5px;
}
.buy-cart{
  width: 30px;
  height: auto;
}
.buy-opts{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.searchbox{
height: 35px;
width: 300px;
position: relative;

background-color: #c5c5c580;
border: none;
padding: 2px;
border-radius: 0px;
}
.oops{
  width: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #222222
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dd-button:hover {
  background-color: #eeeeee;
}


.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
} 

.dd-input:checked + .dd-menu {
  display: block;
} 

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider{
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,100);

body {
  
  font-family: "Roboto", helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}

div.table-title {
   display: block;
  margin: auto;
  padding:5px;
  width: 100%;
}
.data-img{
  width: 70px;
  height: auto;
}
.table-title h3 {
   color: #fafafa;
   font-size: 30px;
   font-weight: 400;
   font-style:normal;
   font-family: "Roboto", helvetica, arial, sans-serif;
   text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
   text-transform:uppercase;
}


/*** Table Styles **/
.add-to-cart{
  color: white !important;
  background-color: #2853e2;
  text-align: center;
  width: 100px;
  border: none;
  cursor: pointer;
  height: 30px;
}
.reset{
color: #2853e2;
background-color: transparent;
border: none;
cursor: pointer;

}
.add-item{
  background-color: transparent;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}
.table-fill {
  background: white;
  border-radius:3px;
  border-collapse: collapse;
  height: 100%;
  margin: auto;

  padding:2px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  animation: float 5s infinite;
}
 
th {
  color:#D5DDE5;;
  background:#1b1e24;
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-size:18px;
  font-weight: 100;
  padding:10px;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

th:first-child {
  border-top-left-radius:3px;
}
 
th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}
  
tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-size:12px;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}
 
tr:hover td {
  background:#4E5066;
  color:#FFFFFF;
  border-top: 1px solid #22262e;
}
 
tr:first-child {
  border-top:none;
}

tr:last-child {
  border-bottom:none;
}
 
tr:nth-child(odd) td {
  background:#EBEBEB;
}
 
tr:nth-child(odd):hover td {
  background:#4E5066;
}

tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}
 
tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}
 
td {
  background:#FFFFFF;
  padding:2px;
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  font-size:16px;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #C1C3D1;
 
}

td:last-child {
  border-right: 0px;
  width: 20%;
}

th.text-left {
  text-align: center;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: center;
}

td.text-left {
  text-align: center;
}
.stocks div {
 width: 100%;
 text-align: center;
}
td.text-center {
  text-align: center;
}

td.text-right {
  text-align: center;
}
@media screen and (max-width:1200px){
  .filter-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    align-items: center;
  }
  .filter-row div{
    display: flex;
    flex-direction: column;
    padding: 5px;
  
  }
  .drops{
    /* height: 70px ; */
    width: 100%;
    /* text-align: center !important; */
  }
}