
a {
    text-decoration: none;
    color: #333333;
  }
  
  a:hover {
    color: #f58551;
  }
  .checkout-page{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
  .button {
    background-color: #16cc9b;
    border: 2px solid #16cc9b;
    color: #ffffff;
    transition: all 0.25s linear;
    cursor: pointer;
  }
  
  .button::after {
    position: relative;
    right: 0;
    content: " \276f";
    transition: all 0.15s linear;
  }
  
  .button:hover {
    background-color: #f58551;
    border-color: #f58551;
  }
  
  .button:hover::after {
    right: -5px;
  }
  
  .button:focus {
    outline: none;
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  input {
    transition: all 0.25s linear;
  }
  
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  input {
    outline: none;
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
    overflow: auto;
  }
  
  /* --- HEADER --- */
  header.container {
    margin-bottom: 1.5rem;
  }
  
  header .breadcrumb {
    color: #7d7d7d;
  }
  
  header .breadcrumb li {
    float: left;
    padding: 0 6px;
  }
  
  header .breadcrumb li:first-child {
    padding-left: 2px;
  }
  
  header .breadcrumb li:not(:last-child)::after {
    content: " \276f";
    padding-left: 8px;
  }
  
  header .count {
    float: right;
    color: #333333;
  }
  
  /* --- PRODUCT LIST --- */
  .container-prods{
    width: 70%;
    padding-left: 20px;

  }
  .products {
    border-top: 1px solid #ddd;
    padding-left: 50px;
  }
  
  .products > li {
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
  }
  .counter-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .counter-display div{
  padding: 10px;
  margin: 3px;
  }
  .row {
    position: relative;
    overflow: auto;
    width: 100%;
  }
  
  .col, .quantity, .remove {
    float: left;
  }
  
  .col.left {
    width: 70%;
  }
  
  .col.right {
    width: 30%;
    position: absolute;
    right: 0;
    top: calc(50% - 30px);
  }
  
  .detail {
    padding: 0 0.5rem;
    line-height: 2.2rem;
  }
  
  .detail .name {
    font-size: 1.2rem;
  }
  
  .detail .description {
    color: #7d7d7d;
    font-size: 1rem;
  }
  
  .detail .price {
    font-size: 1.5rem;
  }
  
  .quantity, .remove {
    width: 50%;
    text-align: center;
  }
  
  .remove svg {
    width: 60px;
    height: 60px;
  }
  
  .quantity > input {
    display: inline-block;
    width: 60px;
    height: 60px;
    position: relative;
    left: calc(50% - 30px);
    background: #fff;
    border: 2px solid #ddd;
    color: #7f7f7f;
    text-align: center;
    font: 600 1.5rem Helvetica, Arial, sans-serif;
  }
  
  .quantity > input:hover, .quantity > input:focus {
    border-color: #f58551;
  }
  
  .close {
    fill: #7d7d7d;
    transition: color 150ms linear,
      background-color 150ms linear,
      fill 150ms linear,
      150ms opacity linear;
    cursor: pointer;
  }
  
  .close:hover {
    fill: #f58551;
  }
  
  /* --- SUMMARY --- */
 
  .summary {
    font-size: 1.2rem;
    text-align: right;
    
  }
  
  .summary ul li {
    padding: 0.5rem 0;
    text-align: left;

  }
  
  .summary ul li span {
    display: inline-block;
    width: 30%;
  }
  
  .summary ul li.total {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
  }
  
  .checkout {
    text-align: left;
    float: left;
  }
  .summary-cont
  {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 40px 10px;
    
  }
  
  .checkout > button {
    font-size: 1.2rem;
    padding: 0.8rem 2.8rem;
    border-radius: 1.5rem;
  }
  
  .empty-product {
    text-align: center;
  }
  
  .empty-product > button {
    font-size: 1.3rem; 
    padding: 10px 30px; 
    border-radius: 5px;
  }
  
  /* --- SMALL SCREEN --- */
  @media all and (max-width: 599px) {
    .thumbnail img {
      display: none;
    }
    
    .quantity > input {
      width: 40px;
      height: 40px;
      left: calc(50% - 20px);
    }
    
    .remove svg {
      width: 40px;
      height: 40px;
    }
  }
  
  /* --- MEDIUM & LARGE SCREEN --- */
@media screen and (max-width:1200px){
  .checkout-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .summary-cont
  {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 40px 10px;
    
  }
  .container-prods{
    width: 100%;
    padding: 0px;

  }
}